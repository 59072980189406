<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <a
          :href="`${apiUrl}/services/${item.slug}`"
          target="_blank"
          class="btn back-to-list btn-outline-info view-on-front"
        >
          {{ $t('general.viewOnFront') }}
        </a>
        <b-button
          type="button"
          variant="outline-secondary"
          class="back-to-list"
          @click="toList"
        >
          {{ $t('general.back') }}
        </b-button>
        <b-form>
          <b-tabs pills>
            <b-tab :title="$t('admin.products.tabs.general.label')">
              <b-tabs>
                <b-tab
                  v-for="language in languages"
                  :key="language.code"
                >
                  <template #title>
                    <b-img
                      :src="require('@/assets/images/flags/' + language.code + '.svg')"
                      height="16"
                      width="16"
                      class="mr-1"
                    />
                    <span class="d-none d-sm-inline">{{ language.title }}</span>
                  </template>
                  <b-row>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.title.label'"
                        :lang="language.code"
                        :field="`title`"
                        :value="item.title[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.title[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['title.' + language.code] ? errors['title.' + language.code][0] : null"
                        @input="value => item.title[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_h1.label'"
                        :lang="language.code"
                        :field="`meta_h1`"
                        :value="item.meta_h1[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_h1[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_h1.' + language.code] ? errors['meta_h1.' + language.code][0] : null"
                        @input="value => item.meta_h1[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_title.label'"
                        :lang="language.code"
                        :field="`meta_title`"
                        :value="item.meta_title[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_title[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_title.' + language.code] ? errors['meta_title.' + language.code][0] : null"
                        @input="value => item.meta_title[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_description.label'"
                        :lang="language.code"
                        :field="`meta_description`"
                        :value="item.meta_description[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_description[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_description.' + language.code] ? errors['meta_description.' + language.code][0] : null"
                        @input="value => item.meta_description[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.meta_keywords.label'"
                        :lang="language.code"
                        :field="`meta_keywords`"
                        :value="item.meta_keywords[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.meta_keywords[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['meta_keywords.' + language.code] ? errors['meta_keywords.' + language.code][0] : null"
                        @input="value => item.meta_keywords[language.code] = value"
                      />
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <b-form-group
                        :label="$t('form.canonical.label')"
                        :label-for="'canonical.' + language.code"
                      >
                        <b-form-input
                          :id="'canonical.' + language.code"
                          v-model="item.canonical[language.code]"
                          :state="errors && errors['canonical.' + language.code] ? false : null"
                        />
                        <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                          {{ errors['canonical.' + language.code][0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="24"
                      md="12"
                    >
                      <GptField
                        :label="'form.description.label'"
                        :lang="language.code"
                        :field="`description`"
                        :value="item.description[language.code]"
                        :title="item.title[language.code]"
                        :translate="item.description[language.code === 'uk' ? 'ru' : 'uk']"
                        :error="errors && errors['description.' + language.code] ? errors['description.' + language.code][0] : null"
                        @input="value => item.description[language.code] = value"
                      />
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.data.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group
                    :label="$t('form.slug.label')"
                    label-for="slug"
                  >
                    <b-form-input
                      id="slug"
                      v-model="item.slug"
                      :state="errors && errors.slug ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.slug">
                      {{ errors.slug[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.currency.label')"
                    label-for="currency_code"
                    :state="errors && errors.currency_code ? false : null"
                  >
                    <v-select
                      id="currency_code"
                      v-model="item.currency_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="currencyOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="currency_code"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.currency_code">
                      {{ errors.currency_code[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.categories.label')"
                    label-for="categories"
                    :state="errors && errors.categories ? false : null"
                  >
                    <v-select
                      id="categories"
                      v-model="item.categories"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptions"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="true"
                      input-id="categories"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.categories">
                      {{ errors.categories[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.top_category.label')"
                    label-for="categories"
                    :state="errors && errors.category_id ? false : null"
                  >
                    <v-select
                      id="category_id"
                      v-model="item.category_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categoryOptionsTop"
                      :reduce="val => val.value"
                      :clearable="true"
                      :multiple="false"
                      input-id="category_id"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.category_id">
                      {{ errors.category_id[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.stickers.label')"
                    label-for="stickers"
                    :state="errors && errors.stickers ? false : null"
                  >
                    <v-select
                      id="stickers"
                      v-model="item.stickers"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="stickersOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      :multiple="true"
                      input-id="stickers"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.stickers">
                      {{ errors.stickers[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.sku.label')"
                    label-for="sku"
                  >
                    <b-form-input
                      id="sku"
                      v-model="item.sku"
                      :state="errors && errors.sku ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.sku">
                      {{ errors.sku[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('form.ean.label')"
                    label-for="ean"
                  >
                    <b-form-input
                      id="ean"
                      v-model="item.ean"
                      :state="errors && errors.ean ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.ean">
                      {{ errors.ean[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.status.label')"
                    label-for="status"
                    :state="errors && errors.status ? false : null"
                  >
                    <v-select
                      id="status"
                      v-model="item.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.status">
                      {{ errors.status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.indexing_status.label')"
                    label-for="indexing_status"
                    :state="errors && errors.indexing_status ? false : null"
                  >
                    <v-select
                      id="indexing_status"
                      v-model="item.indexing_status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="indexing_status"
                    />
                    <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                      {{ errors.indexing_status[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    :label="$t('form.position.label')"
                    label-for="position"
                  >
                    <b-form-input
                      id="position"
                      v-model="item.position"
                      type="number"
                      :state="errors && errors.position ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.position">
                      {{ errors.position[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.images.label')">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group :label="$t('form.avatar.label')">
                    <!-- media -->
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                            height="80"
                          />
                        </b-link>
                        <!--/ avatar -->

                        <!-- reset -->
                        <b-button
                          v-if="item.avatar"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.avatar = null"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                        <!--/ reset -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="avatar"
                          type="product_avatar"
                          :multiple="false"
                          @fm-selected="selectedAvatar"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group :label="$t('form.gallery.label')">
                    <b-media no-body>
                      <b-media-aside
                        v-for="(file, index) in item.gallery"
                        :key="index"
                      >
                        <b-link>
                          <b-img
                            ref="previewEl"
                            rounded
                            :src="file.url"
                            height="80"
                          />
                        </b-link>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="item.gallery.splice(index, 1)"
                        >
                          {{ $t('general.reset') }}
                        </b-button>
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <InitFileManager
                          field="gallery"
                          type="product_gallery"
                          :multiple="true"
                          @fm-selected="selectedGallery"
                        />

                        <b-row
                          v-if="errors && errors.avatar"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors.avatar[0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>
                        <b-row
                          v-if="errors && errors['avatar.type']"
                        >
                          <b-col cols="12">
                            <b-form-invalid-feedback :state="false">
                              {{ errors['avatar.type'][0] }}
                            </b-form-invalid-feedback>
                          </b-col>
                        </b-row>

                      </b-media-body>
                    </b-media>
                    <!--/ media -->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    :label="$t('form.video.label')"
                    label-for="video"
                  >
                    <b-form-input
                      id="video"
                      v-model="item.video"
                      :state="errors && errors.code ? false : null"
                    />

                    <b-form-invalid-feedback v-if="errors && errors.video">
                      {{ errors.video[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.prices.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(price, index) in item.service_prices"
                  :id="'price' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="$t('form.role.label')"
                      :label-for="'service_prices.' + index + '.role_id'"
                      :state="errors && errors['service_prices.' + index + '.role_id'] ? false : null"
                    >
                      <v-select
                        :id="'service_prices.' + index + '.role_id'"
                        v-model="price.role_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="rolesOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        :selectable="priceRolesOptionsSelectable"
                        :input-id="'service_prices.' + index + '.role_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['service_prices.' + index + '.role_id']">
                        {{ errors['service_prices.' + index + '.role_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <MultiPrice
                      v-model="price.price"
                      :index="index"
                      :label="$t('form.price.label')"
                      :type="'service_prices'"
                      :currency="item.currency_code"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      :label="`+ {n} %`"
                      :label-for="'service_prices.' + index + '.percent'"
                    >
                      <b-form-input
                        id="percent"
                        v-model="price.percent"
                        type="number"
                        step="1"
                        min="1"
                        max="999999"
                        :state="errors && errors['service_prices.' + index + '.percent'] ? false : null"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['service_prices.' + index + '.percent']">
                        {{ errors['service_prices.' + index + '.percent'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removePrice(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addPrice"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.service_prices"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.service_prices[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.attributes.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(attribute, index) in item.attributes"
                  :id="'attribute' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      :label="$t('form.attribute_id.label')"
                      :label-for="'attributes.' + index + '.attribute_id'"
                      :state="errors && errors['attributes.' + index + '.attribute_id'] ? false : null"
                    >
                      <v-select
                        :id="'attributes.' + index + '.attribute_id'"
                        v-model="attribute.attribute_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="uniqAttributes"
                        :selectable="attributesSelectable"
                        :reduce="val => val.value"
                        :clearable="false"
                        :input-id="'attributes.' + index + '.attribute_id'"
                        @search="searchAttribute"
                        @input="selectAttribute($event, index)"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['attributes.' + index + '.attribute_id']">
                        {{ errors['attributes.' + index + '.attribute_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <ProductAttributeValue
                      v-model="attribute.attribute_value_id"
                      :index="index"
                      :attribute-id="attribute.attribute_id"
                      :languages="languages"
                      :error="errors && errors['attributes.' + index + '.attribute_value_id'] ? errors['attributes.' + index + '.attribute_value_id'][0] : null"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeAttribute(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addAttribute"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>
              <b-row
                v-if="errors && errors.attributes"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.attributes[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab :title="$t('admin.products.tabs.connections.label')">
              <div class="repeater-form">
                <b-row
                  v-for="(connection, index) in item.service_connections"
                  :id="'connection' + index"
                  :key="index"
                  ref="row"
                >
                  <b-col cols="12">
                    Виключення
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.top_category.label')"
                      :label-for="'service_connections.' + index + '.dosent_category_id'"
                      :state="errors && errors['service_connections.' + index + '.dosent_category_id'] ? false : null"
                    >
                      <v-select
                        :id="'service_connections.' + index + '.dosent_category_id'"
                        v-model="connection.dosent_category_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="connectionCategoryOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        :input-id="'service_prices.' + index + '.dosent_category_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['service_connections.' + index + '.dosent_category_id']">
                        {{ errors['service_connections.' + index + '.dosent_category_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <SingleProductAttributeValue
                      v-model="connection.dosent_attribute_value_id"
                      :index="index"
                      :attribute-id="1"
                      :languages="languages"
                      :error="errors && errors['connection.' + index + '.dosent_attribute_value_id'] ? errors['connection.' + index + '.dosent_attribute_value_id'][0] : null"
                    />
                  </b-col>

                  <b-col cols="12">
                    Включення
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      :label="$t('form.top_category.label')"
                      :label-for="'service_connections.' + index + '.category_id'"
                      :state="errors && errors['service_connections.' + index + '.category_id'] ? false : null"
                    >
                      <v-select
                        :id="'service_connections.' + index + '.category_id'"
                        v-model="connection.category_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="connectionCategoryOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        :input-id="'service_prices.' + index + '.category_id'"
                      />
                      <b-form-invalid-feedback v-if="errors && errors['service_connections.' + index + '.category_id']">
                        {{ errors['service_connections.' + index + '.category_id'][0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <SingleProductAttributeValue
                      v-model="connection.attribute_value_id"
                      :index="index"
                      :attribute-id="1"
                      :languages="languages"
                      :error="errors && errors['connection.' + index + '.attribute_value_id'] ? errors['connection.' + index + '.attribute_value_id'][0] : null"
                    />
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <label>{{ $t('admin.table.fields.price') }}</label>
                    <b-row>
                      <b-col
                        cols="6"
                        class="mb-1"
                      >
                        <b-form-input
                          v-model="connection.price_from"
                          :placeholder="'from'"
                          type="number"
                          step="1"
                          min="0"
                          max="999999"
                        />
                      </b-col>
                      <b-col
                        cols="6"
                        class="mb-1"
                      >
                        <b-form-input
                          v-model="connection.price_to"
                          :placeholder="'to'"
                          type="number"
                          step="1"
                          min="0"
                          max="999999"
                        />
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-row>
                      <b-col cols="8">
                        <b-form-group
                          :label="$t('form.include_child.label')"
                          :label-for="'service_connections.' + index + '.include_child'"
                        >
                          <b-form-checkbox
                            :id="'service_connections.' + index + '.include_child'"
                            v-model="connection.include_child"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="4">
                        <b-form-group
                          :label="$t('form.cron.label')"
                          :label-for="'service_connections.' + index + '.cron'"
                        >
                          <b-form-checkbox
                            :id="'service_connections.' + index + '.cron'"
                            v-model="connection.cron"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="2"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      block
                      @click="removeConnection(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="addConnection"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>{{ $t('general.add') }}</span>
              </b-button>

              <b-row
                v-if="errors && errors.service_connections"
              >
                <b-col cols="12">
                  <b-form-invalid-feedback :state="false">
                    {{ errors.service_connections[0] }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'services-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import CategoryMixin from '@/mixins/CategoryMixin'
import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import MultiPrice from '@/components/MultiPrice.vue'
import GptField from '@/components/GptField.vue'
import ProductAttributeValue from '@/components/ProductAttributeValue.vue'
import SingleProductAttributeValue from '@/components/SingleProductAttributeValue.vue'

export default {
  components: {
    SingleProductAttributeValue,
    MultiPrice,
    GptField,
    ProductAttributeValue,
  },
  mixins: [CategoryMixin, GlobalMixin],
  data() {
    return {
      item: null,
      oldCategoryId: null,
      apiUrl: process.env.VUE_APP_APP_URL,
      itemModData: {},
      languages: [],
      statuses: [],
      typeOptions: [
        { label: this.$t('general.product_types.simple'), value: 'simple' },
        { label: this.$t('general.product_types.variable'), value: 'variable' },
      ],
      loading: {
        categories: false,
        stocks: false,
        roles: false,
        attributes: false,
      },
      categories: [],
      categoryOptions: [],
      currencyOptions: [],
      stickersOptions: [],
      extensionOptions: [],
      rolesOptions: [],
      productOptions: [],
      productBaseOptions: [],
      attributesOptions: [],
      attributesBaseOptions: [],
      connectionCategoryOptions: [],
    }
  },
  computed: {
    categoryOptionsTop() {
      return _.filter(this.categoryOptions, cat => _.includes(this.item.categories, cat.value))
    },
    uniqAttributes() {
      return _.uniqBy(_.concat(this.attributesBaseOptions, this.attributesOptions), 'value')
    },
    uniqProducts() {
      return _.uniqBy(_.concat(this.productBaseOptions, this.productOptions), 'value')
    },
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })
    await this.$http.get('/api/admin/statuses')
      .then(response => {
        this.statuses = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    await this.$http.get('/api/stocks', { params: { status: 'enabled', per_page: 100 } })
      .then(response => {
        this.stocksOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })

    this.$http('/api/currencies')
      .then(response => {
        this.currencyOptions = this.$options.filters.transformForVSelect(response.data.data, 'code', 'title')
      })

    await this.$http.get(`/api/admin/products/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)

        this.loadRoles()
        this.loadAttributes()
        this.loadDataTab()

        if (this.item.name) {
          this.$route.meta.breadcrumb.pop()
          this.$route.meta.breadcrumb.push({ text: this.item.name, active: true })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'products-index' })
        }
      })
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedGallery(data) {
      this.item.gallery = _.concat(this.item.gallery, data)
    },
    async loadDataTab() {
      if (!Object.keys(this.categoryOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/admin/fullcategories', {
          params: {
            type: 'service',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categories = response.data
            this.categoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.stickersOptions).length) {
        this.loading.categories = false

        await this.$http.get('/api/stickers', { params: { status: 'enabled', per_page: 100 } })
          .then(response => {
            this.stickersOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
            this.loading.categories = true
          })
          .catch(() => {
            this.loading.categories = true
          })
      }
      if (!Object.keys(this.connectionCategoryOptions).length) {
        await this.$http.get('/api/admin/fullcategories', {
          params: {
            type: 'product',
            status: 'enabled',
          },
        })
          .then(response => {
            this.categories = response.data
            this.connectionCategoryOptions = this.$options.filters.transformForVSelect(response.data, 'id', 'title')
          })
      }
    },
    loadRoles() {
      if (Object.keys(this.rolesOptions).length) {
        return
      }

      this.$http.get('/api/roles', { params: { mode: 'client', per_page: 100 } })
        .then(response => {
          this.rolesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    loadAttributes() {
      this.$http.get('/api/attributes', {
        params: {
          sort_by: 'title', sort_desc: false, status: 'enabled', per_page: 20,
        },
      })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    selectProduct(id) {
      if (!_.includes(_.map(this.productBaseOptions, 'value'), id)) {
        this.productBaseOptions.push(this.productOptions.find(a => a.value === id))
      }
    },
    async selectAttribute(id, index) {
      if (!_.includes(_.map(this.attributesBaseOptions, 'value'), id)) {
        this.attributesBaseOptions.push(this.attributesOptions.find(a => a.value === id))
      }

      if (index === undefined) return

      this.item.attributes[index].attribute_value_id = []
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        slug: this.item.slug,
        type: this.item.type,
        video: this.item.video,
        currency_code: this.item.currency_code,
        category_id: this.item.category_id,
        categories: this.item.categories,
        stickers: this.item.stickers,
        service_prices: this.item.service_prices,
        service_connections: this.item.service_connections,
        attributes: this.item.attributes.reduce((acc, item) => {
          const expandedItems = item.attribute_value_id.map(valueId => ({
            attribute_id: item.attribute_id,
            attribute_value_id: valueId,
          }))
          return acc.concat(expandedItems)
        }, []),
        avatar: this.item.avatar,
        gallery: this.item.gallery,
        status: this.item.status,
        indexing_status: this.item.indexing_status,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        sku: this.item.sku,
        ean: this.item.ean,
        position: this.item.position,
        available_at: this.item.available_at,
        novelty_until: this.item.novelty_until,
        stock_status_id: this.item.stock_status_id,
      }

      this.$http.put(`/api/admin/products/${this.item.id}`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Збережено',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          router.replace({
            name: 'services-update',
            params: { id: this.item.id },
            query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
          })
          this.$store.dispatch('validation/clearErrors')
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    addAttribute() {
      this.item.attributes.push({
        attribute_id: 0,
        attribute_value_id: [],
      })
    },
    removeAttribute(index) {
      this.item.attributes.splice(index, 1)
    },
    addPrice() {
      this.item.service_prices.push({
        price: 1,
        percent: 0,
        role_id: null,
      })
    },
    removePrice(index) {
      this.item.service_prices.splice(index, 1)
    },
    addConnection() {
      this.item.service_connections.push({
        category_id: null,
        sticker_id: null,
        attribute_value_id: null,
        dosent_category_id: null,
        dosent_attribute_value_id: null,
        price_from: 0,
        price_to: 0,
        include_child: false,
        cron: false,
      })
    },
    removeConnection(index) {
      this.item.service_connections.splice(index, 1)
    },
    transformData(item) {
      const data = {
        id: item.id,
        slug: item.slug,
        type: item.type,
        video: item.video,
        currency_code: item.currency_code,
        category_id: item.category_id,
        avatar: this.$options.filters.singleMedia(item.avatar),
        gallery: this.$options.filters.multipleMedia(item.gallery),
        categories: item.categories,
        attributes: [],
        service_prices: [],
        service_connections: item.service_connections || [],
        stickers: item.stickers,
        status: item.status,
        indexing_status: item.indexing_status,
        sku: item.sku,
        ean: item.ean,
        position: item.position,
        available_at: item.available_at ? item.available_at : new Date(),
        novelty_until: item.novelty_until ? item.novelty_until : new Date(),
        stock_status_id: item.stock_status.id,
        name: item.title,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
      }
      this.oldCategoryId = item.category_id

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.categories = _.map(data.categories, 'id')
      data.stickers = _.map(data.stickers, 'id')

      data.service_prices = _.map(item.service_prices, price => ({
        id: price.id,
        role_id: price.role_id,
        price: price.price,
        percent: price.percent,
      }))

      data.attributes = _.map(
        _.reduce(item.attributes, (acc, i) => {
          if (acc[i.attribute_id]) {
            acc[i.attribute_id].push(i.attribute_value_id)
          } else {
            acc[i.attribute_id] = [i.attribute_value_id]
          }
          return acc
        }, {}),
        (value, key) => ({
          attribute_id: parseInt(key, 10),
          attribute_value_id: value,
        }),
      )

      this.attributesBaseOptions = this.$options.filters.transformForVSelect(_.map(item.attributes, a => a.attribute), 'id', 'title')

      return data
    },
    toList() {
      router.replace({
        name: 'services-index',
        query: { ...this.$route.query, ...{ updated: Math.floor(Date.now() / 1000) } },
      })
    },
    priceRolesOptionsSelectable(option) {
      return !_.includes(_.map(this.item.prices, price => price.role_id), option.value)
    },
    attributesSelectable(option) {
      return !_.includes(_.map(this.item.attributes, ipm => ipm.attribute_id), option.value)
    },
    async searchAttribute(query) {
      if (!query.length) return
      await this.$http.get('/api/attributes', { params: { query, per_page: 20 } })
        .then(response => {
          this.attributesOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
        })
    },
    async searchProduct(query) {
      if (!query.length) return
      await this.$http.get('/api/admin/products', {
        params: {
          query,
        },
      })
        .then(response => {
          this.productOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
          if (this.item.sets?.length) {
            _.each(this.item.sets, set => {
              if (set.entity_id) {
                this.$http.get(`/api/admin/products/${set.entity_id}`)
                  .then(res => {
                    this.productOptions.push({ label: res.data.data.title, value: set.entity_id })
                    this.productOptions = _.uniqBy(this.productOptions, 'value')
                  })
              }
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.back-to-list {
  position: absolute;
  top: -75px;
  right: -20px;
}

.view-on-front {
  position: absolute;
  top: -75px;
  right: 80px;
}

.open-modifications {
  position: absolute;
  top: -75px;
  right: 210px;
}
</style>
